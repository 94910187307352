import { UAParser } from 'ua-parser-js'

const uaResult = UAParser()

window.onload = function() {
  const googleTranslateElementInit = () => {
    const translateElement = uaResult.device.type === 'mobile' ? 'google_translate_element_sp' : 'google_translate_element'

    new google.translate.TranslateElement({pageLanguage: 'ja', includedLanguages: 'en,ja,zh-CN,zh-TW,ko,kr,es,fr,id,ru,de,it,no,da,sv,ar,fi,is,et,lv,lt,nl', layout: google.translate.TranslateElement.InlineLayout.SIMPLE}, translateElement)
  }

  googleTranslateElementInit()
}
